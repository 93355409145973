import React from 'react';
import { useGetAllBounties } from 'src/cms';
import { ContentSection } from 'src/components/layout/layout';
import 'twin.macro';
import BipCard from '../lib/bipCard';
import { LockIcon } from '../lib/icons';
import { PlaceholderBox, TextBox } from './style';

export const Gallery = () => {
  const bounties = useGetAllBounties();

  return (
    <ContentSection>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
        {bounties.map((bounty, index) => (
          <div key={index}>
            <BipCard clickable lg={false} id={bounty.id} title={bounty.title} author={bounty.author} />
          </div>
        ))}
        <PlaceholderBox>
          <LockIcon />
          <TextBox>More Bounties coming soon...</TextBox>
        </PlaceholderBox>
      </div>
    </ContentSection>
  );
};
