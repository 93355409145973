import styled from 'styled-components';

export const PlaceholderBox = styled.div`
  background: linear-gradient(180deg, #fbfbfb 0%, rgba(243, 243, 243, 0) 92.48%);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 344px;
`;

export const TextBox = styled.div`
  padding-top: 16px;
`;
