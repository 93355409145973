import * as React from 'react';
import { Gallery } from 'src/components/bounties';
import { Layout } from 'src/components/layout/layout';

const IndexPage = () => {
  return (
    <Layout>
      <Gallery />
    </Layout>
  );
};
export default IndexPage;
